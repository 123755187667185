import { Plan } from '@logichat/domains/sdk'
import { FirebaseConfig } from '@logichat/firebase'

export class BootstrapConfig {
  production!: boolean
  firebase!: FirebaseConfig
  openai!: {
    openaiApiKey: string
    openaiUrl: string
  }
  stripe!: {
    token: string
  }
  plans!: Plan[]
}
