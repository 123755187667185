export const PORTAL_ROOT_ROUTE = ''
export const DASHBOARD_ROOT_ROUTE = 'dashboard'
export const APPS_ROOT_ROUTE = 'apps'
export const INTEGRATIONS_ROOT_ROUTE = 'integrations'
export const USAGE_ROOT_ROUTE = 'usage'
export const AUTH_ROOT_ROUTE = 'auth'
export const SETTINGS_ROOT_ROUTE = 'settings'

// ADMIN
export const GODMODE_ROOT_ROUTE = 'godmode'
