import { APP_INITIALIZER } from '@angular/core'
import { OpenAIConfig } from '@logichat/domains/openai'
import { BootstrapConfig } from '.'

export const openaiConfig: OpenAIConfig = {
  openaiApiKey: '',
  openaiUrl: '',
}

const factory = (config: BootstrapConfig, openai: OpenAIConfig) => {
  return () => {
    openai.openaiUrl = config.openai.openaiUrl
    openai.openaiApiKey = config.openai.openaiApiKey
  }
}

export const openaiConfigProvider = {
  provide: APP_INITIALIZER,
  useFactory: factory,
  deps: [BootstrapConfig, OpenAIConfig],
  multi: true,
}
